@import "../../assets/scss/base";

.inputTextLine {
  color: #111;

  /* Color */
  &--color-red,
  &--color-red {
    color: red;
  }

  &--color-blue,
  &--color-blue {
    color: blue;
  }

  &--color-grey,
  &--color-grey {
    color: grey;
  }

  /* Text alignment */
  &--textalign-left,
  &--textalign-left {
    text-align: left;
  }

  &--textalign-center,
  &--textalign-center {
    text-align: center;
  }

  &--textalign-right,
  &--textalign-right {
    text-align: right;
  }

  /* Style */
  &--bold,
  &--bold {
    font-weight: bold;
  }

  &--italic,
  &--italic {
    font-style: italic;
  }

  &--underline,
  &--underline {
    text-decoration: underline;
  }

}

.file-upload-delete {
  background: rgba(255, 255, 255, 0.5);
}