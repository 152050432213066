@import "../../assets/scss/base";

.zoom-select-container {
    text-align: end;
}

.page-break{
    page-break-after: always;
}

.no-break {
    page-break-after: avoid;
}

.bg-color-pdf {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0px;
    left: 0px;
  }


@media print{
    .print-no-background{
        margin: 0px 56px 0px 56px !important;
    }
}