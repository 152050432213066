.richtext-preview {
    & ol {
        padding-left: 1.5em;
    }
}

.ql-editor {
    & a {
        text-decoration: underline;
        color: #06c;
        cursor: pointer;
    }
}