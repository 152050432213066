.dynamic-table-edit-cell-button {
    display: none;
}

.cell-dinamic-table:hover .dynamic-table-edit-cell-button {
    display: flex;
}

.table th {
    position: relative;
}

.resizer {
    /* Displayed at the right side of column */
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
}

.resizer:hover,
.resizing {
    border-right: 2px solid blue;
}