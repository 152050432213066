@import "../../assets/scss/base";

.picker {
    position: relative;
  }
  
  .popover {
    position: absolute;
    top: calc(100% + 2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);

    .react-colorful {
        height: 120px;
      }

    .react-colorful__saturation {
      height: 150px;
      border-radius: 2px 2px 0 0;
    }
    .react-colorful__hue {
        display: block;
        border-radius: 0 0 2px 2px;
    }
  }